<template>
  <div class="container-fluid">
      <b-row>
        <b-col sm="12" md="7" lg="7" xl="7" class="bg-login align-items-center d-none d-lg-flex">
          <div class="ml-5">
            <img alt="WYA Yoga" src="../../assets/images/logo/logo.png">
            <h1 class="text-white mt-3 font-weight-bold display-3">{{ $t('WORLD YOGA ALLIANCE') }}</h1>
            <h5 class="text-white mt-4">{{ $t('International Yoga Organization') }}</h5>
            <br>
            <b-button pill variant="light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 font-weight-bold header-btn-register">{{ $t('Learn more') }}</b-button>
            <b-button pill variant="outline-light" :to="{ name: 'searchDirectory' }" class="mr-3 px-5 my-4 ml-2 font-weight-bold header-btn-register">{{ $t('Search') }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" md="12" lg="5" xl="5" class="d-flex justify-content-center align-items-center">
          <div class="mt-5 mt-lg-0 px-3 pt-3">
            <div class="d-flex justify-content-center"><h1 class="text-primary-light d-inline font-weight-bold header-login">{{ $t('Login') }}</h1></div>
            <p class="text-center text-gray-light mt-5">{{ $t('Welcome back! Login to access WYA Community.') }} <br>{{ $t('Don’t have an account?') }} <router-link :to="{ name: 'authenSignup' }" class="text-danger-light">{{ $t('Sign up') }}</router-link></p>
            <div class="mt-5">
              <ValidationObserver v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                <validation-provider
                  :name="$t('Email')"
                  rules="required"
                  v-slot="{errors}"
                >
                  <b-form-group class="font-weight-bold text-primary-dark" for="input-username" :label="$t('Email')">
                    <b-form-input
                      id="input-username"
                      class="input-login"
                      type="text"
                      v-model="form.username"
                      :placeholder="$t('Email')">
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  :name="$t('Password')"
                  rules="required"
                  v-slot="{errors}"
                >
                  <b-form-group class="font-weight-bold text-primary-dark" for="input-username" :label="$t('Password')">
                    <b-form-input
                      id="input-password"
                      class="input-login"
                      type="password"
                      :placeholder="$t('Password')"
                      v-model="form.password"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <small class="text-danger" v-if="textError">{{ textError }}</small>
                <router-link :to="{ name: 'authenResetpassword' }" class="float-right text-danger-light mt-3 mb-4">{{ $t('Forget your password?') }}</router-link><br>
                <b-button pill block type="submit" class="mr-3 mt-4 px-5 my-4 font-weight-bold btn-primary-dark btn-lg" :disabled="isLoading"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."></b-spinner>{{ $t('Login') }}</b-button>
              </b-form>
              </ValidationObserver>
            </div>
            <div class="text-center">
              <h5 class="font-weight-bold text-primary-dark">{{ $t('Login with') }}</h5>
              <span class="d-block">
                <b-button aria-pressed="true" class="btn btn-lg btn-light rounded-circle bg-facebook"><font-awesome-icon :icon="['fab', 'facebook-f']" class="text-white"/>
                <facebook-login class="button btn-facebook" ref="btnFacebookLogin"
                    appId="183526770103553"
                    @login="onFacebookLogin"
                    @logout="onFacebookLogout"
                    loginLabel=""
                    @sdk-loaded="sdkLoaded"
                    style="width: 45px;height:45px;
                    position: absolute;
                    margin-top: -35px;
                    margin-left: -30px;"
                  >
                </facebook-login></b-button>
                <a href="#" role="button" aria-pressed="true" class="btn btn-lg rounded-circle bg-white" @click="handleClickSignIn"><b-img alt="login with google" width="43" :src="require('../../assets/images/icon/google.png')"></b-img></a>
              </span>
              <span class="text-gray-light d-block mt-5">
                <small class="text-gray-light">Copyright © World Yoga Alliance® 1990 - {{ currentYear }}</small>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import facebookLogin from 'facebook-login-vuejs'

export default {
  name: 'Login',
  title: ' | Login',
  data () {
    return {
      currentYear: new Date().getFullYear(),
      form: {
        username: '',
        password: ''
      },
      isLoading: false,
      textError: '',
      FB: undefined,
      isConnected: false
    }
  },
  components: {
    ValidationObserver,
    facebookLogin
  },
  mounted () {
    window.scrollTo(0, 0)
    this.isConnected = false
    this.$store.dispatch('clientCredential')
  },
  beforeCreate () {
    if (this.$store.getters.loggedIn) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    onSubmit () {
      this.isLoading = true
      this.textError = ''
      this.$store.dispatch('login', { username: this.form.username, password: this.form.password })
        .then((response) => {
          this.isLoading = false
          this.$router.push({ name: 'userProfile' })
        })
        .catch(() => {
          this.isLoading = false
          this.textError = this.$t('Invalid username or password')
        })
    },
    sdkLoaded (payload) {
      this.isConnected = payload.isConnected
      this.FB = payload.FB
      if (this.isConnected) this.signInWithFacebook()
    },
    handleClickSignIn () {
      this.$gAuth.signIn()
        .then(user => {
          this.isSignIn = this.$gAuth.isAuthorized
          const accessToken = (user.uc ? user.uc.access_token : null) || (user.tc ? user.tc.access_token : null) || (user.qc ? user.qc.access_token : null)
          this.$store.dispatch('authenWithSocial', { token: accessToken, backend: 'google-oauth2' })
            .then((response) => {
              this.$router.push({ name: 'userProfile' })
            })
            .catch(() => {
              this.$notify({
                group: 'error',
                text: this.$t('Login unsuccessful')
              })
            })
        })
        .catch(() => {
          this.$notify({
            group: 'error',
            text: this.$t('Login unsuccessful')
          })
        })
    },
    onFacebookLogin () {
      this.isConnected = true
      this.signInWithFacebook()
    },
    signInWithFacebook () {
      if (this.isConnected) {
        const accessToken = this.FB.getAccessToken()
        this.$store.dispatch('authenWithSocial', { token: accessToken, backend: 'facebook' })
          .then((response) => {
            this.$router.push({ name: 'userProfile' })
          })
          .catch(() => {
            this.$notify({
              group: 'error',
              text: this.$t('Login unsuccessful')
            })
          })
      }
    },
    onFacebookLogout () {
      this.isConnected = false
    }
  }
}
</script>
<style>
  .input-login {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #DBDBDB;
  }
  .header-login {
    padding-bottom: 10px;
    border-bottom: 2px solid #F04B4C;
  }
  .bg-facebook {
    background: transparent linear-gradient(135deg, #4676ED 0%, #436DE4 19%, #3B54CD 49%, #2F2DA8 87%, #2B1E99 100%) 0% 0% no-repeat padding-box;
  }
  div.spinner {
    opacity: 0 !important;
  }
  .btn-facebook button {
    opacity: 0 !important;
  }
</style>
